.dataimg-arrow-left {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/arrow-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-left {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/arrow-light-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-right {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/arrow-light-right.svg"); background-repeat: no-repeat;}

.dataimg-arrow-right {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/arrow-right.svg"); background-repeat: no-repeat;}

.dataimg-check-icon {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/check-icon.svg"); background-repeat: no-repeat;}

.dataimg-cross-fat {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/cross-fat.svg"); background-repeat: no-repeat;}

.dataimg-cross {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/cross.svg"); background-repeat: no-repeat;}

.dataimg-download-icon {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/download-icon.svg"); background-repeat: no-repeat;}

.dataimg-facebook {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/facebook.svg"); background-repeat: no-repeat;}

.dataimg-icon-premium-gopay {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/icon-premium-gopay.svg"); background-repeat: no-repeat;}

.dataimg-icon-premium-master-card {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/icon-premium-master-card.svg"); background-repeat: no-repeat;}

.dataimg-icon-premium-sms {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/icon-premium-sms.svg"); background-repeat: no-repeat;}

.dataimg-icon-premium-visa {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/icon-premium-visa.svg"); background-repeat: no-repeat;}

.dataimg-login-icon {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/login-icon.svg"); background-repeat: no-repeat;}

.dataimg-login {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/login.svg"); background-repeat: no-repeat;}

.dataimg-logo-horizontal {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/logo-horizontal.svg"); background-repeat: no-repeat;}

.dataimg-logo-onecolor {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/logo-onecolor.svg"); background-repeat: no-repeat;}

.dataimg-payment-gopay {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/payment-gopay.svg"); background-repeat: no-repeat;}

.dataimg-payment-mastercard {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/payment-mastercard.svg"); background-repeat: no-repeat;}

.dataimg-payment-sms {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/payment-sms.svg"); background-repeat: no-repeat;}

.dataimg-payment-visa {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/payment-visa.svg"); background-repeat: no-repeat;}

.dataimg-premium-2 {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/premium-2.svg"); background-repeat: no-repeat;}

.dataimg-search {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/search.svg"); background-repeat: no-repeat;}

.dataimg-tag-blesk-premium {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/tag-blesk-premium.svg"); background-repeat: no-repeat;}

.dataimg-twitter {background-image: url("https://img2.cncenter.cz/images/blesk-prozeny/dist/svg/twitter.svg"); background-repeat: no-repeat;}

.dataimg-scissors {background-image: url("https://img2.cncenter.cz/images/default/user-bonus/scissors.svg"); background-repeat: no-repeat;}
